<template>
    <div class="breadcrumbs_area mt-45">
        <div class="container">   
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb_content">
                        <ul>
                            <li><a href="index.html">home</a></li>
                            <li>portfolio</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>         
    </div>
    <div class="portfolio_section_area mt-45 mb-45">
        <div class="portfolio_button">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="portfolio_tab_button"> 
                            <button class="active" data-filter="*">all</button>   
                            <button  data-filter=".company">Company</button>   
                            <button  data-filter=".computers">Computers</button>   
                            <button  data-filter=".general">General</button>   
                            <button  data-filter=".hipster">Hipster</button>   
                            <button  data-filter=".food">Just Food</button>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="portfolio_tab">
            <div class="container-fluid p-0">
                <div class="row no-gutters portfolio_gallery">  
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item computers general">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port1.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port1.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item computers food">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port12.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port12.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item company general">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port3.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port3.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item computers hipster">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port4.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port4.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item computers general food">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port5.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port5.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item company general hipster">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port6.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port6.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item computers company food">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port7.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port7.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item computers general Hipster">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port8.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port8.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item company general hipster food">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port9.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port9.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item computers company hipster food">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port10.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port10.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item computers company general food">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port11.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port11.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 gird_item computers company general hipster">
                        <div class="single_portfolio_inner">
                            <div class="portfolio_thumb">
                                <a href="#"><img src="assets/img/portfolio/port12.jpg" alt=""></a>
                                <div class="portfolio_popup">
                                    <a class="port_popup" href="assets/img/portfolio/port12.jpg"><i class="fa fa-search"></i></a>
                                </div>
                                <div class="portfolio_link">
                                    <a href="portfolio-details.html"><i class="fa fa-link"></i></a>
                                </div>
                                <div class="portfolio_content">
                                    <a href="portfolio-details.html">Coffee & Cookie Time</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>